import { EnumsMediaType } from '@lp-lib/api-service-client/public';

import type { GamePack } from '../../../types/game';
import { fromMediaDTO } from '../../../utils/api-dto';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../../utils/media';
import { type OverworldDisplayOptions } from './types';

export function OverworldBackground(props: {
  pack: Nullable<GamePack>;
  displayOptions?: OverworldDisplayOptions;
  position?: string;
}) {
  const { pack, displayOptions } = props;

  const media = pack?.marketingSettings?.lobbyBackground?.media;
  const url = MediaUtils.PickMediaUrl(fromMediaDTO(media), {
    priority: ImagePickPriorityHighToLow,
  });

  return !url || displayOptions?.disableBg ? null : (
    <div className={`${props.position ?? 'absolute'} inset-0`}>
      {media?.type === EnumsMediaType.MediaTypeVideo ? (
        <video
          src={url}
          muted
          autoPlay
          loop
          className='w-full h-full object-cover object-center opacity-10'
        />
      ) : (
        <img
          src={url}
          alt=''
          className={`w-full h-full object-cover object-center opacity-10 ${
            displayOptions?.roundedBg ? 'rounded-xl' : ''
          }`}
        />
      )}
    </div>
  );
}
